import { instrumentService } from '~/modules/SDK/services/instruments';
export const dealerList = [
    { label: '國票', name: 'ibf' },
    { label: '群益', name: 'capital' },
];
export const dealerOrderUrl = {
    ibf: {
        futures: {
            long: 'B',
            short: 'S',
        },
    },
    capital: {
        futures: {
            long: 'B',
            short: 'S',
        },
    },
};
// 定義券商 URL 配置
export const dealerUrlConfig = {
    ibf: {
        tx: {
            baseUrl: 'https://itrade.ibfs.com.tw/APP/FuturesLite/Forder.aspx?',
            params: (symbol, longShort, agentName) => `&no=${translateSymbolToIbfContractName(symbol)}&volumn=1&trade=${dealerOrderUrl.ibf.futures[longShort]}&c=${agentName}`,
        },
        stockfuture: {
            baseUrl: 'https://itrade.ibfs.com.tw/APP/FuturesLite/Forder.aspx?',
            params: (symbol, longShort, agentName) => `&no=${translateSymbolToIbfContractName(symbol)}&volumn=1&trade=${dealerOrderUrl.ibf.futures[longShort]}&c=${agentName}`,
        },
        // no:商品代碼
        // volumn:數量
        // trade:買賣別，只有B與S
        // c:xxxx投顧老師代碼(名單可以再另外整理對應公司)
    },
    capital: {
        tx: {
            baseUrl: 'https://tradeweb.capital.com.tw/capitalh5/Login.aspx?',
            params: (symbol, longShort) => `source=zz&DefaultCond=F&DisplayCond=F&FuntureID=${symbol}&FuntureBS=${dealerOrderUrl.capital.futures[longShort]}`,
        },
        stockfuture: {
            baseUrl: 'https://tradeweb.capital.com.tw/capitalh5/Login.aspx?',
            params: (symbol, longShort) => `source=zz&DefaultCond=F&DisplayCond=F&FuntureID=${'FI' + symbol}&FuntureBS=${dealerOrderUrl.capital.futures[longShort]}`,
        },
    },
};
// 統一處理期貨和股票期貨的合約名稱轉換
function translateSymbolToIbfContractName(symbol) {
    const instrument = instrumentService.getInstrument(symbol);
    const monthCode = instrument.symbol.frontMonth;
    if (symbol === 'FITX') {
        return 'TXF' + monthCode;
    }
    else if (symbol === 'FIMTX') {
        return 'MXF' + monthCode;
    }
    else {
        return symbol + monthCode;
    }
}
